import React, { useReducer, useEffect, useState, Fragment } from 'react'
import { lead,buyers,report } from '../util/db'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import moment from 'moment'
import DatePicker from "react-datepicker";
import {  Label, FormGroup, Modal, ModalHeader, ModalBody, Table, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSVLink } from "react-csv";

import "react-datepicker/dist/react-datepicker.css";

const initialState = {
    vendor_id: null,
    buyer_id: null,
    vertical_id: null,
    id: null,
    start_date: null,
    end_date: null,
    traceMessage: null,
    traceType: null,
    alertsName: null,
    alertsMessage: null,
    sort: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'vendor_id':
            return { ...state, vendor_id: action.payload }
        case 'buyer_id':
            return { ...state, buyer_id: action.payload }
        case 'vertical_id':
            return { ...state, vertical_id: action.payload }
        case 'id':
            return { ...state, id: action.payload }
        case 'start_date':
            return { ...state, start_date: action.payload }
        case 'end_date':
            return { ...state, end_date: action.payload }
        case 'traceMessage':
            return { ...state, traceMessage: action.payload }
        case 'traceType':
            return { ...state, traceType: action.payload }
        case 'page':
            return { ...state, page: action.payload }
        case 'size':
            return { ...state, size: action.payload }
        case 'sort':
            return { ...state, sort: action.payload }
        case 'reset':
            return initialState
        default:
            return state
    }
}

function StackTrace(props){
    const [state, dispatch] = useReducer(reducer, initialState)
    const [traceResults, setTraceResults] = useState([])
    const [traceResultsPages, setTraceResultsPages] = useState(-1)
    const [traceResultsTotal, setTraceResultsTotal] = useState(0)
    const [traceResultsPerPage, setTraceResultsPerPage] = useState(20)
    const [traceResultsLoading, setTraceResultsLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(500)
    const [sort, setSort] = useState([])
    const [displayStartDate, setDisplayStartDate] = useState(null)
    const [displayEndDate, setDisplayEndDate] = useState(null)
    const [modal, setModal] = useState(false)
    const [displayData, setDisplayData] = useState([])

    // useEffect(() => {
    //     handleSearch()
    // }, [])

    useEffect(() => {
        handleSearch()
    }, [page, size, state.sort])

    const handleSearch = async () => {
        setTraceResultsLoading(true)
        let params = [{
            filter: state.filter,
            sort: state.sort,
            vendor_id: state.vendor_id,
            buyer_id: state.buyer_id,
            vertical_id: state.vertical_id,
            id: state.id,
            start_date: state.start_date,
            end_date: state.end_date,
            traceMessage: state.traceMessage,
            traceType: state.traceType
        }]

        let all = true

        Object.keys(state).map(items => {
            if(state[items] !== null){
                all = false
            }
        })

        // let params = []
        // Object.keys(state).map(items => {
        //         if(Array.isArray(state[items]) && state[items].length > 0) {
        //             params.push({[items]: state[items]})
        //         } else if(Array.isArray(state[items]) && state[items].length === 0) {
        //             delete params[items]
        //         } else if (state[items] !== null && state[items] !== '') {
        //             params.push({[items]: state[items]})
        //         } else {
        //             delete params[items]
        //         }
        // })


        const search = await lead(params, all == false ? 'stack-filter' : 'stack-filter-all', {
            page: page,
            size: size
        })
        if(search && search.items){
            setTraceResults(search.items)
            setTraceResultsPages(search._meta ? search._meta.pageCount : -1)
            setTraceResultsTotal(search._meta ? search._meta.totalCount : 0)
            setTraceResultsPerPage(search._meta ? search._meta.perPage : 20)
            setTraceResultsLoading(false)
        }
       
    }

    const handlePageChange = (pageUpdate) => {
        setPage(pageUpdate)
      }
      
      
      const handleSortedChange = (newSorted, column, shiftKey) => {
        console.log(newSorted)
        dispatch({ type: 'sort', payload: newSorted })
      }
      
      const handlePageSizeChange = (pageSize,pageIndex) => {
        setSize(pageSize)
      }

      const handleDate = (date, dateType) => {
            dispatch({ type: dateType, payload: moment(date).format('YYYY-MM-DD') })
            if(dateType === 'start_date'){
                setDisplayStartDate(date)
            } else {
                setDisplayEndDate(date)
            }
      }

      const toggle = () => {
        setModal(!modal)
        setDisplayData([])
      }

      const handleModal = (e, id, type) => {
        e.preventDefault()
        setModal(!modal)
        if(id && type === 'lead_id'){
           setDisplayData(traceResults.filter(item => item.lead_id === id))
        } else {
            setDisplayData(traceResults.filter(item => item.id === id))
        }
      }


      const handleClear = async () => { 
        dispatch({ type: "reset"})
        setDisplayStartDate(null)
        setDisplayEndDate(null)
        setPage(1)
        setSize(500)
        setTraceResultsLoading(true)
        let params = initialState
        const search = await lead(params, 'stack-filter-all', {
            page: 1,
            size: 500
        })
        if(search && search.items){
            setTraceResults(search.items)
            setTraceResultsPages(search._meta ? search._meta.pageCount : -1)
            setTraceResultsTotal(search._meta ? search._meta.totalCount : 0)
            setTraceResultsPerPage(search._meta ? search._meta.perPage : 20)
            setTraceResultsLoading(false)
        }
      }
        
     let exportList = []
        traceResults.map(item => {
            exportList.push({
                'ID': item.id,
                'Lead ID': item.lead_id,
                'Vendor ID': item.vendor_id,
                'Vendor': item.vendor,
                'Trace Type': item.type,
                'Trace Message': item && item.message && item.message.length > 0 ?  item.message.replace(/,/g, ';') : '',
                'Trace Date': item.timestamp
            })
        })

    return(
        <div>
            <div className="row g-3 align-items-center">
                    <div className="col-auto">
                        <div className="form-group">
                            <label htmlFor="vendor_id">Vendor ID</label>
                            <input type="text" className="form-control" id="vendor_id" placeholder="Enter Vendor ID" onChange={(e) => dispatch({ type: 'vendor_id', payload: e.target.value })} />
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group">
                            <label htmlFor="lead_id">Lead ID</label>
                            <input type="text" className="form-control" id="lead_id" placeholder="Enter Lead ID" onChange={(e) => dispatch({ type: 'id', payload: e.target.value })} />
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group">
                            <label htmlFor="traceMessage">Trace Message</label>
                            <input type="text" className="form-control" id="traceMessage" placeholder="Enter Trace Message" onChange={(e) => dispatch({ type: 'traceMessage', payload: e.target.value })} />
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group">
                            <label htmlFor="traceType">Trace Type</label>
                            <input type="text" className="form-control" id="traceType" placeholder="Enter Trace Type" onChange={(e) => dispatch({ type: 'traceType', payload: e.target.value })} />
                        </div>
                    </div>
                    <div className="col-auto">
                    <Label>Start Date</Label>
                        <FormGroup>
                            <DatePicker
                                selected={displayStartDate}
                                onChange={date => handleDate(date, 'start_date')}
                                selectsStart
                                startDate={displayStartDate}
                                endDate={displayEndDate}
                                dateFormat="yyyy-MM-dd"
                            />
                            {' '}
                        </FormGroup> 
                     </div>
                    <div className="col-auto">
                        <Label>End Date </Label>
                        <FormGroup>
                            <DatePicker
                                selected={displayEndDate}
                                onChange={date => handleDate(date, 'end_date')}
                                selectsEnd
                                startDate={displayStartDate}
                                endDate={displayEndDate}
                                dateFormat="yyyy-MM-dd"
                            />
                        </FormGroup>  
                    </div>
            </div>
            <div>
                <button type="submit" className="btn btn-primary" onClick={(e) => {
                            e.preventDefault()
                            handleSearch()
                        }}>Search</button>{ ' ' }
                <button type="submit" className="btn btn-warning" onClick={(e) => {
                            e.preventDefault()
                            handleClear()
                        }}>Clear Filters</button>{ ' '}
                <CSVLink 
                            data={exportList|| []}
                            filename={`stack-trace${Date.now()}.csv`}
                            className="btn btn-danger"
                            target="_blank"
                            style={{marginRight: "20px"}}
                        >
                            Export data
                </CSVLink>   
            </div>
            <div className="container-fluid" style={{backgroundColor: "white"}}>
                        {displayData.length > 0 ?
                            <DisplayModal stack={displayData} toggle={toggle} modal={modal} />
                        :''}
                        <div style={{padding: "10px"}}>
                            <StackTraceDisplay
                                data={traceResults}
                                pages={traceResultsPages}
                                total={traceResultsTotal}
                                perPage={traceResultsPerPage}
                                loading={traceResultsLoading}
                                handlePageChange={handlePageChange}
                                handleSortedChange={handleSortedChange}
                                handlePageSizeChange={handlePageSizeChange}
                                handleModal={handleModal}
                            />
                        </div>
                    </div>         
        </div>
    )
}

const DisplayModal = (props) => {
    const stack = props.stack
    return(
        <Modal isOpen={props.modal} toggle={props.toggle} size="xl">
            <ModalHeader toggle={props.toggle}>Trace Details</ModalHeader>
            <ModalBody>
            <Table>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Message</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stack && stack.length > 0 ? Object.keys(stack).map(items => 
                                    <tr key={stack[items].id}>
                                        <td>{stack[items].type}</td>
                                        <td >
                                            <pre>
                                                {isJson(stack[items].message) ? JSON.stringify(JSON.parse(stack[items].message), null, 2) : stack[items].message}
                                            </pre>
                                        </td>
                                        <td>{stack[items].timestamp}</td>
                                    </tr>
                                ):  
                                    <tr>
                                        <td>No information abou this lead"</td>
                                    </tr>
                                }
                            </tbody>
                        </Table> 
            </ModalBody>
        </Modal>
    )
}

const StackTraceDisplay = props => {
    return(
        <ReactTable
            data={props.data}
            columns={
            [
                {
                    Header: "Trace id",
                    accessor: "id",
                    id: "id",
                    Cell: function(prop){
                        return (
                            <Fragment>
                            <a href="#" id="view" onClick={(e) => props.handleModal(e, prop.original.id, 'id')}>{prop.original.id}</a>
                            </Fragment>
                        )  
                    }   
                    },
                {
                Header: "Lead Id",
                accessor: "lead_id",
                id: "lead_id",
                Cell: function(prop){
                    return (
                        <Fragment>
                        <a href="#" id="view" onClick={(e) => props.handleModal(e, prop.original.lead_id, 'lead_id')}>{prop.original.lead_id}</a>
                        </Fragment>
                    )  
                }   
                },
                {
                Header: "Vendor ID",
                accessor: "vendor_id",
                id: "vendor_id"
                },
                {
                Header: "Vendor",
                accessor: "vendor",
                id: "vendor",
                style: { 'whiteSpace': 'unset' }
                },
                {
                Header: "Type",
                accessor: "type",
                id: "type"
                },
                {
                Header: "Message",
                accessor: "message",
                id: "message",
                style: { 'whiteSpace': 'pre'}
                },
                {
                Header: "Timestamp",
                accessor: "timestamp",
                id: "timestamp",
                style: { 'whiteSpace': 'unset' }
                }
            ]
            }
            manual
            loading={props.loading}
            pages={props.pages}
            pageSize={props.data ? props.data.length : 20}
            onPageChange={(pageIndex) => props.handlePageChange(pageIndex + 1)}
            onFilteredChange={(filtered, column) => props.handleFilteredChange(filtered, column)}
            filterable={false}
            onSortedChange={(newSorted, column, shiftKey) => props.handleSortedChange(newSorted)}
            pageSizeOptions={[5, 10, 20, 25, 50, 100, 200, 500, 1000]}
            onPageSizeChange={(pageSize, pageIndex) => props.handlePageSizeChange(pageSize, pageIndex)}
            // getTrProps={(state, rowInfo) => ({
            //     onClick: () => leadsDisplay(rowInfo)
            // })}
            className="-highlight"
        />   
    )
}

function isJson(str){
    try {   
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const AlertDisplay = props => {

}

export default StackTrace